<template>
	<div>

		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<router-link :to="{name: 'projects-index'}">Projects</router-link>
				</li>

				<li class="breadcrumb-item" v-if="project">
					<router-link :to="{
								name: 'projects-editor-uuid-index',
								params: {
									uuid: project.Uuid
								}
							}" v-text="`${project.Code} - ${project.Name}`"></router-link>
				</li>

				<li class="breadcrumb-item">
					<router-link :to="{
						name: 'projects-editor-uuid-cuuid-localizations-index',
						params: {
							uuid: this.$route.params.uuid,
							cuuid: this.$route.params.cuuid
						}
					}" v-if="configuration">{{ configuration.Name }} {{ `v. ${configuration.Version}` }}
					</router-link>
				</li>

				<li class="breadcrumb-item active" v-if="localization_project">
					<router-link :to="{
						name: 'projects-editor-uuid-cuuid-localizations-project_id-index',
						params: {
							uuid: this.$route.params.uuid,
							cuuid: this.$route.params.cuuid,
							project_id: this.$route.params.project_id
						}
					}">{{ localization_project.Name }}
					</router-link>
				</li>

				<li class="breadcrumb-item active">
					Downloads
				</li>

			</ol>
		</nav>

		<template>

			<div class="row d-flex justify-content-center mt-5">
				<div class="col-lg-6 py-5">
					<div class="p-5 bg-primary text-white text-center">
						<h2 v-if="localization_project">Download the zip containing all the files for {{ localization_project.Name }} translation</h2>

						<div class="mt-5">

							<div v-if="loading" class="spinner-border text-white" role="status">
								<span class="sr-only">Loading...</span>
							</div>
							<button v-else class="btn btn-outline-white px-5 py-3" @click="download">
								<div>DOWNLOAD ZIP</div>
							</button>
						</div>
					</div>
				</div>
			</div>

		</template>


	</div>
</template>

<script>
import api from '@/api'

export default {
	name: "download",
	data: () => ({
		loading: false,
		project: null,
		configuration: null,
		localization_project: null,
	}),
	async mounted() {
		this.loading = true
		this.project = await api.Projects.getProject(this.$route.params.uuid)
		this.configuration = await api.Projects.getProjectConfig(this.$route.params.cuuid)
		this.localization_project = await api.Localizations.LocalizationProjects.get(this.$route.params.project_id)
		this.loading = false
	},
	methods: {
		async download() {
			this.loading = true
			const zip_file = await api.Localizations.LocalizationProjects.downloadPackage(this.$route.params.project_id)
			console.log("IS BLOB?", zip_file instanceof Blob)

			const filename = `locale-pack-${this.localization_project.Name}.zip`

			let fileURL = window.URL.createObjectURL(zip_file);
			let fileLink = document.createElement('a');

			fileLink.href = fileURL;
			fileLink.setAttribute('download', filename);
			document.body.appendChild(fileLink);
			fileLink.click();
			this.loading = false
		}
	},
	computed: {}
}
</script>

<style scoped>

</style>
