var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{name: 'projects-index'}}},[_vm._v("Projects")])],1),(_vm.project)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
							name: 'projects-editor-uuid-index',
							params: {
								uuid: _vm.project.Uuid
							}
						}},domProps:{"textContent":_vm._s(`${_vm.project.Code} - ${_vm.project.Name}`)}})],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item"},[(_vm.configuration)?_c('router-link',{attrs:{"to":{
					name: 'projects-editor-uuid-cuuid-localizations-index',
					params: {
						uuid: this.$route.params.uuid,
						cuuid: this.$route.params.cuuid
					}
				}}},[_vm._v(_vm._s(_vm.configuration.Name)+" "+_vm._s(`v. ${_vm.configuration.Version}`)+" ")]):_vm._e()],1),(_vm.localization_project)?_c('li',{staticClass:"breadcrumb-item active"},[_c('router-link',{attrs:{"to":{
					name: 'projects-editor-uuid-cuuid-localizations-project_id-index',
					params: {
						uuid: this.$route.params.uuid,
						cuuid: this.$route.params.cuuid,
						project_id: this.$route.params.project_id
					}
				}}},[_vm._v(_vm._s(_vm.localization_project.Name)+" ")])],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Downloads ")])])]),[_c('div',{staticClass:"row d-flex justify-content-center mt-5"},[_c('div',{staticClass:"col-lg-6 py-5"},[_c('div',{staticClass:"p-5 bg-primary text-white text-center"},[(_vm.localization_project)?_c('h2',[_vm._v("Download the zip containing all the files for "+_vm._s(_vm.localization_project.Name)+" translation")]):_vm._e(),_c('div',{staticClass:"mt-5"},[(_vm.loading)?_c('div',{staticClass:"spinner-border text-white",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_c('button',{staticClass:"btn btn-outline-white px-5 py-3",on:{"click":_vm.download}},[_c('div',[_vm._v("DOWNLOAD ZIP")])])])])])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }